import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { ApiService } from '../../core/services/api.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { ACTIONS_LOCAL_STORAGE_KEY } from '../../impact/actions/consts/actions.consts';
import type { ApiResponse } from '../../shared/models/response';
import type { VerifyActionsLoginBEResponse } from '../models/auth-be.models';
import type { ActionsCustomClaims } from '../models/auth-fe.models';

@Injectable({
  providedIn: 'root',
})
export class ActionsAuthService {
  readonly #api = inject(ApiService);
  readonly #localStorage = inject(LocalStorageService);

  verify(
    hash: string,
    codeId: string,
  ): Observable<ApiResponse<VerifyActionsLoginBEResponse>> {
    return this.#api.get('api', '/verify', {
      params: {
        hash,
        code_id: codeId,
        module: 'actions',
      },
    });
  }

  preserveCustomClaimsToStorage(customClaims: ActionsCustomClaims): void {
    this.#localStorage.setItem(ACTIONS_LOCAL_STORAGE_KEY, {
      customClaims,
    });
  }

  getCustomClaims(): ActionsCustomClaims | undefined {
    return this.#localStorage.getItem(ACTIONS_LOCAL_STORAGE_KEY)?.customClaims;
  }
}
