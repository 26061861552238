import { routerReducer, type RouterReducerState } from '@ngrx/router-store';
import type { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { logoutFail, logoutSuccess } from '../../../auth/store/actions';
import type { CountriesState } from '../states/countries.state';
import { countriesReducer } from './countries.reducer';

export interface State {
  router: RouterReducerState;
  countries: CountriesState;
}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer,
  countries: countriesReducer,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: delete the comment and fix the error
export function logout(reducer: any) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: delete the comment and fix the error
  return function (state: any, action: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call -- TODO: delete the comment and fix the error
    return reducer(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- TODO: delete the comment and fix the error
      action.type === logoutSuccess.type || action.type === logoutFail.type ?
        undefined
      : state,
      action,
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: delete the comment and fix the error
export const metaReducers: Array<MetaReducer<any>> = [logout];
