import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import type { Action } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';
import { ERROR_DETAILS_KEY } from '../../../core/consts/error.consts';
import { getErrorDetails } from '../../../core/helpers/get-error-details.helper';
import { getErrorMessage } from '../../../core/helpers/get-error-message.helper';
import {
  LoggerService,
  MessageLevel,
} from '../../../core/services/logger.service';
import { isArray } from '../../../shared/helpers/is-array/is-array.helper';
import * as MessageActions from '../../../shared/store/actions';
import { SharedFacade } from '../../../shared/store/shared.facade';
import { signupFormDTOsToSignupForms } from '../../models/signup.models';
import { SignupService } from '../../services/signup.service';
import * as SignupActions from '../actions/signup.actions';

@Injectable()
export class SignupEffects {
  readonly #actions$ = inject(Actions);
  readonly #loggerService = inject(LoggerService);
  readonly #sharedFacade = inject(SharedFacade);
  readonly #signupService = inject(SignupService);

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly getForms$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(SignupActions.getForms),
      concatLatestFrom(() => [this.#sharedFacade.accountId$]),
      switchMap(([, accountId]) =>
        this.#signupService.getSignupForms(accountId).pipe(
          map((formDTOs) =>
            SignupActions.getFormsSuccess({
              forms: signupFormDTOsToSignupForms(formDTOs),
            }),
          ),
          catchError((error: unknown) => {
            this.#logError(error);

            return of(SignupActions.getFormsFail({ error }));
          }),
        ),
      ),
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly getBackgroundImage$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(SignupActions.getBackgroundImage),
      switchMap(({ formId }) =>
        this.#signupService.getFormBackgroundImage(formId).pipe(
          map((backgroundImageBase64) =>
            SignupActions.getBackgroundImageSuccess({
              backgroundImageBase64:
                isArray(backgroundImageBase64) ? '' : backgroundImageBase64,
            }),
          ),
          catchError((error: unknown) => {
            this.#logError(error);

            return of(SignupActions.getBackgroundImageFail({ error }));
          }),
        ),
      ),
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly enqueueErrorSnackbar$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(SignupActions.getFormsFail),
      map((action) =>
        MessageActions.enqueueErrorSnackbar({
          configuration: {
            translationKey: this.#getSnackbarErrorMessageKey(action),
          },
        }),
      ),
    );
  });

  #getSnackbarErrorMessageKey(action: Action & { error: unknown }): string {
    if (action.type === SignupActions.getFormsFail.type) {
      return 'authModule.snackbarMessage.getFormsFail';
    }

    return 'shared.defaultErrorMessage';
  }

  #logError(error: unknown): void {
    this.#loggerService.log({
      level: MessageLevel.Error,
      message: getErrorMessage(error),
      data: {
        [ERROR_DETAILS_KEY]: getErrorDetails(error),
      },
      shouldBeLoggedToConsole: true,
    });
  }
}
