import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as MessageActions from '../../../shared/store/actions';
import { CountriesService } from '../../services/countries.service';
import * as CountriesActions from '../actions/countries.actions';

@Injectable()
export class CountriesEffects {
  readonly #actions$ = inject(Actions);
  readonly #countriesService = inject(CountriesService);

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly loadCountries$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(CountriesActions.loadCountries),
      switchMap(() =>
        this.#countriesService.getCountries().pipe(
          map((countries) =>
            CountriesActions.loadCountriesSuccess({ countries }),
          ),
          catchError(({ message }) =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: delete the comment and fix the error
            observableOf(CountriesActions.loadCountriesFail({ message })),
          ),
        ),
      ),
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly showFailMessage$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(CountriesActions.loadCountriesFail),
      map(() => MessageActions.showFailMessage({ payload: {} })),
    );
  });
}
