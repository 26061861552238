import { z } from 'zod';

const roleDtoSchema = z.union([
  z.literal('admin'),
  z.literal('company'),
  z.literal('account'),
  z.literal('customer'),
  z.literal('viewer'),
  z.literal('supplier'),
  z.literal('auditor'),
]);

export type RoleDTO = z.infer<typeof roleDtoSchema>;

export const userDtoSchema = z.object({
  id: z.number(),
  name: z.string(),
  role: roleDtoSchema,
  isOutOfOffice: z.boolean(),
});

export type UserDTO = z.infer<typeof userDtoSchema>;

export const usersDtoSchema = z.array(userDtoSchema);

export type UsersDTO = z.infer<typeof usersDtoSchema>;
