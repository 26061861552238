import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { z } from 'zod';
import { ApiV2Service } from '../../core/services/api-v2.service';
import { ApiService, type Options } from '../../core/services/api.service';
import type { AccountDetailsDTO } from '../models/account-details.dto.models';
import type {
  NotificationModeAndScheduleDTO,
  RuleDTO,
} from '../models/rules.dto.models';
import type { SupportedConditionTypesDTO } from '../models/supported-condition-types.dto.models';
import {
  userDtoSchema,
  usersDtoSchema,
  type UserDTO,
  type UsersDTO,
} from '../models/users.dto.models';

export interface NotificationsServiceModel {
  getRules: (accountId: number) => Observable<RuleDTO[]>;

  getRule: (accountId: number, ruleId: RuleDTO['id']) => Observable<RuleDTO>;

  createRule: (accountId: number, rule: RuleDTO) => Observable<RuleDTO>;

  updateRule: (accountId: number, rule: RuleDTO) => Observable<RuleDTO>;

  activateRule: (
    accountId: number,
    ruleId: RuleDTO['id'],
  ) => Observable<RuleDTO>;

  deactivateRule: (
    accountId: number,
    ruleId: RuleDTO['id'],
  ) => Observable<RuleDTO>;

  removeAmendmentsFromRule: (
    accountId: number,
    ruleId: RuleDTO['id'],
  ) => Observable<RuleDTO>;

  deleteRule: (accountId: number, ruleId: RuleDTO['id']) => Observable<unknown>;

  bulkChangeRuleStates: (
    accountId: number,
    ruleIds: ReadonlyArray<RuleDTO['id']>,
    state: RuleDTO['state'],
  ) => Observable<Array<Pick<RuleDTO, 'id'> & NotificationModeAndScheduleDTO>>;

  updateRuleIsMuted: (
    accountId: number,
    ruleId: RuleDTO['id'],
    isMuted: boolean,
  ) => Observable<RuleDTO>;

  getUsers: (accountId: number) => Observable<UsersDTO>;

  getAccountUsers: (accountId: number) => Observable<UsersDTO>;

  updateUserIsOutOfOffice: (
    accountId: number,
    isOutOfOffice: UserDTO['isOutOfOffice'],
  ) => Observable<UserDTO>;

  getAccountDetails: (accountId: number) => Observable<AccountDetailsDTO>;

  getSupportedConditionTypes: (
    accountId: number,
  ) => Observable<SupportedConditionTypesDTO>;

  unsubscribeNotifications: (token: string) => Observable<unknown>;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsService implements NotificationsServiceModel {
  readonly #apiV1Service = inject(ApiService);
  readonly #apiV2Service = inject(ApiV2Service);

  getRules(accountId: number): Observable<RuleDTO[]> {
    return this.#apiV1Service.get(
      'notificationsApi',
      '/ui/v2/rules',
      this.#getOptions(accountId),
    );
  }

  getRule(accountId: number, ruleId: RuleDTO['id']): Observable<RuleDTO> {
    return this.#apiV1Service.get(
      'notificationsApi',
      `/ui/v2/rules/${ruleId}`,
      this.#getOptions(accountId),
    );
  }

  createRule(accountId: number, rule: RuleDTO): Observable<RuleDTO> {
    return this.#apiV1Service.post(
      'notificationsApi',
      '/ui/v2/rules',
      rule,
      this.#getOptions(accountId),
    );
  }

  updateRule(accountId: number, rule: RuleDTO): Observable<RuleDTO> {
    return this.#apiV1Service.put(
      'notificationsApi',
      `/ui/v2/rules/${rule.id}`,
      rule,
      this.#getOptions(accountId),
    );
  }

  activateRule(accountId: number, ruleId: RuleDTO['id']): Observable<RuleDTO> {
    return this.#apiV1Service.put(
      'notificationsApi',
      `/ui/v2/rules/${ruleId}/state`,
      {
        state: 'Active',
      },
      this.#getOptions(accountId),
    );
  }

  deactivateRule(
    accountId: number,
    ruleId: RuleDTO['id'],
  ): Observable<RuleDTO> {
    return this.#apiV1Service.put(
      'notificationsApi',
      `/ui/v2/rules/${ruleId}/state`,
      {
        state: 'Inactive',
      },
      this.#getOptions(accountId),
    );
  }

  removeAmendmentsFromRule(
    accountId: number,
    ruleId: RuleDTO['id'],
  ): Observable<RuleDTO> {
    return this.#apiV1Service.delete(
      'notificationsApi',
      `/ui/v2/rules/${ruleId}/amendments`,
      this.#getOptions(accountId),
    );
  }

  deleteRule(accountId: number, ruleId: RuleDTO['id']): Observable<unknown> {
    return this.#apiV1Service.delete(
      'notificationsApi',
      `/ui/v2/rules/${ruleId}`,
      this.#getOptions(accountId),
    );
  }

  bulkChangeRuleStates(
    accountId: number,
    ruleIds: ReadonlyArray<RuleDTO['id']>,
    state: RuleDTO['state'],
  ): Observable<Array<Pick<RuleDTO, 'id'> & NotificationModeAndScheduleDTO>> {
    return this.#apiV1Service.put(
      'notificationsApi',
      '/ui/v2/bulk/rules/state',
      {
        ruleIds,
        newState: state,
      },
      this.#getOptions(accountId),
    );
  }

  updateRuleIsMuted(
    accountId: number,
    ruleId: RuleDTO['id'],
    isMuted: boolean,
  ): Observable<RuleDTO> {
    return this.#apiV1Service.put(
      'notificationsApi',
      `/ui/v2/rules/${ruleId}/mute-settings`,
      {
        isMuted,
      },
      this.#getOptions(accountId),
    );
  }

  getUsers(accountId: number): Observable<UsersDTO> {
    return this.#apiV2Service.get({
      request: {
        url: {
          apiName: 'notificationsApi',
          path: '/ui/v2/users',
          queryParameters: {
            accountId: String(accountId),
          },
        },
      },
      response: {
        schema: usersDtoSchema,
      },
    });
  }

  getAccountUsers(accountId: number): Observable<UsersDTO> {
    return this.#apiV2Service.get({
      request: {
        url: {
          apiName: 'notificationsApi',
          path: '/ui/v2/account-users',
          queryParameters: {
            accountId: String(accountId),
          },
        },
      },
      response: {
        schema: usersDtoSchema,
      },
    });
  }

  updateUserIsOutOfOffice(
    accountId: number,
    isOutOfOffice: UserDTO['isOutOfOffice'],
  ): Observable<UserDTO> {
    return this.#apiV2Service.put({
      request: {
        url: {
          apiName: 'notificationsApi',
          path: '/ui/v2/user/out-of-office',
          queryParameters: {
            accountId: String(accountId),
          },
        },
        body: {
          isOutOfOffice,
        },
        // TODO: move this somewhere else
        schema: z
          .object({
            isOutOfOffice: z.boolean(),
          })
          .strict(),
      },
      response: {
        schema: userDtoSchema,
      },
    });
  }

  getAccountDetails(accountId: number): Observable<AccountDetailsDTO> {
    return this.#apiV1Service.get(
      'notificationsApi',
      '/ui/v2/account/details',
      this.#getOptions(accountId),
    );
  }

  getSupportedConditionTypes(
    accountId: number,
  ): Observable<SupportedConditionTypesDTO> {
    return this.#apiV1Service.get(
      'notificationsApi',
      '/ui/v2/supported-condition-types',
      this.#getOptions(accountId),
    );
  }

  unsubscribeNotifications(token: string): Observable<unknown> {
    return this.#apiV1Service.post(
      'notificationsApi',
      `/ui/v2/unsubscription/${token}`,
      {},
    );
  }

  #getOptions(this: void, accountId: number): Options {
    return {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        accountId: String(accountId),
      },
    };
  }
}
