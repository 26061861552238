import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { ApiService } from '../../core/services/api.service';
import type { SignupForm } from '../models/signup.models';

export interface SignupServiceModel {
  getSignupForms: (accountId: number) => Observable<SignupForm[]>;
  getFormBackgroundImage: (formId: string) => Observable<string | []>;
}

@Injectable({
  providedIn: 'root',
})
export class SignupService implements SignupServiceModel {
  readonly #api = inject(ApiService);

  getSignupForms(accountId: number): Observable<SignupForm[]> {
    return this.#api.get(
      'synchronizationsApi',
      `/v1/hubspot/forms/${accountId}`,
    );
  }

  getFormBackgroundImage(formId: string): Observable<string | []> {
    return this.#api.get('synchronizationsApi', '/v1/hubspot/image/forms', {
      params: { formId },
      responseType: 'text',
    });
  }
}
