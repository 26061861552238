import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store, type Action } from '@ngrx/store';
import { catchError, EMPTY, filter, map, of, switchMap, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { createUserManagementSettings } from '../../../core/helpers/create-user-management-settings.helper';
import { DialogService } from '../../../core/services/dialog.service';
import { IntercomService } from '../../../core/services/intercom.service';
import {
  LoggerService,
  MessageLevel,
} from '../../../core/services/logger.service';
import * as fromRoot from '../../../core/store';
import * as RouterActions from '../../../core/store/actions/router.action';
import { ACCOUNT_ID_PARAMETER_NAME } from '../../../impact/actions/consts/actions.consts';
import { NotificationsService } from '../../../notifications/services/notifications.service';
import {
  auth0Config,
  defaultRedirectPath,
} from '../../../shared/consts/auth.consts';
import { Roles } from '../../../shared/consts/roles.enum';
import { MappedRoles } from '../../../shared/consts/user-management.consts';
import { assert } from '../../../shared/helpers/assert/assert.helper';
import { isNumber } from '../../../shared/helpers/is-number/is-number.helper';
import { isString } from '../../../shared/helpers/is-string/is-string.helper';
import { isTruthy } from '../../../shared/helpers/is-truthy/is-truthy.helper';
// eslint-disable-next-line unicorn/prevent-abbreviations -- TODO: delete the comment and fix the error
import { getQueryStringParams } from '../../../shared/helpers/utils.helper';
import type { ApiResponse } from '../../../shared/models/response';
import * as fromMessageActions from '../../../shared/store/actions';
import type { ActionsCustomClaims } from '../../models/auth-fe.models';
import { ActionsAuthService } from '../../services/actions-auth.service';
import { AuthService } from '../../services/auth.service';
import * as authActions from '../actions';
import { selectLoadLogoutStatus } from '../reducers';

@Injectable()
export class AuthEffect {
  readonly #actions$ = inject(Actions);
  readonly #actionsAuthService = inject(ActionsAuthService);
  readonly #auth0 = auth0Config;
  readonly #authService = inject(AuthService);
  readonly #dialog = inject(DialogService);
  readonly #intercomService = inject(IntercomService);
  readonly #loggerService = inject(LoggerService);
  readonly #notificationsService = inject(NotificationsService);
  readonly #route = inject(ActivatedRoute);
  readonly #router = inject(Router);
  readonly #store = inject(Store);
  readonly #translate = inject(TranslocoService);

  // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
  readonly login$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(authActions.login),
      map((action) => action.payload),
      switchMap((payload) =>
        this.#authService.login(payload).pipe(
          map((response) => this.#getActionBasedOnLoginResponse(response)),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: delete the comment and fix the error
          catchError((error) => of(authActions.loginFail({ payload: error }))),
        ),
      ),
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
  readonly getSSOConnection$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(authActions.getSSOConnection),
      map((action) => action.payload),
      switchMap(({ domain }) =>
        this.#authService.getSSOConnection(domain).pipe(
          map((firstResult) => {
            assert(
              !firstResult.done && !!firstResult.value,
              'Error while processing SSO connection name result',
            );

            return authActions.getSSOConnectionSuccess({
              payload: { connectionName: firstResult.value.ConnectionName },
            });
          }),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: delete the comment and fix the error
          catchError((error) => {
            this.#loggerService.log({
              level: MessageLevel.Error,
              message: 'Error while retrieving SSO connection name',
              data: {
                error: String(error),
              },
            });

            return of(authActions.getSSOConnectionFail());
          }),
        ),
      ),
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
  readonly getAccountSSOConnection$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(authActions.getAccountSSOConnection),
      map((action) => action.payload),
      switchMap(({ name }) =>
        this.#authService.getAccountSSOConnection(name).pipe(
          map((firstResult) => {
            assert(
              !firstResult.done && !!firstResult.value,
              'Error while processing account SSO connection name result',
            );

            return authActions.getAccountSSOConnectionSuccess({
              payload: { connectionName: firstResult.value.ConnectionName },
            });
          }),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: delete the comment and fix the error
          catchError((error) => {
            this.#loggerService.log({
              level: MessageLevel.Error,
              message: 'Error while retrieving account SSO connection name',
              data: {
                error: String(error),
              },
            });

            return of(authActions.getAccountSSOConnectionFail());
          }),
        ),
      ),
    );
  });

  readonly loginWithSSO$ = createEffect(
    // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
    () => {
      return this.#actions$.pipe(
        ofType(
          authActions.getSSOConnectionSuccess,
          authActions.getAccountSSOConnectionSuccess,
        ),
        filter(
          ({ payload: { connectionName } }) =>
            !!connectionName && connectionName.trim() !== '',
        ),
        tap(({ payload: { connectionName } }) => {
          this.#auth0.authorize({ connection: connectionName });
        }),
      );
    },
    { dispatch: false },
  );

  readonly loginWithSSOFail$ = createEffect(
    // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
    () => {
      return this.#actions$.pipe(
        ofType(authActions.loginWithSSOFail),
        map(({ payload: { error, errorMessage } }) => {
          this.#loggerService.log({
            level: MessageLevel.Error,
            message: errorMessage,
            data: {
              error: error,
            },
          });

          return fromMessageActions.enqueueErrorSnackbar({
            configuration: {
              translationKey: errorMessage,
            },
          });
        }),
      );
    },
  );

  // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
  readonly sendLink$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(authActions.sendLink),
      map((action) => action.payload),
      switchMap((payload) =>
        this.#authService.sendLink(payload).pipe(
          map((response) => {
            if (response.status === true) {
              return authActions.sendLinkSuccess({
                payload: {
                  response,
                  message: this.#translate.translate(
                    'authModule.linkSentSuccessfully',
                  ),
                },
              });
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: delete the comment and fix the error
            return authActions.sendLinkFail({ payload: response.data });
          }),
          catchError((error) =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: delete the comment and fix the error
            of(authActions.sendLinkFail({ payload: error })),
          ),
        ),
      ),
      // eslint-disable-next-line unicorn/prevent-abbreviations -- TODO: delete the comment and fix the error
      switchMap((responseObj) => {
        if (responseObj.type === authActions.sendLinkSuccess.type) {
          // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects -- TODO: delete the comment and fix the error
          return [
            responseObj,
            RouterActions.go({ payload: { path: ['login'] } }),
          ];
        }
        // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects -- TODO: delete the comment and fix the error
        return [responseObj];
      }),
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
  readonly externalLogin$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(authActions.externalLogin),
      map((action) => action.payload),
      switchMap((payload) =>
        this.#authService.loginExternal(payload).pipe(
          map((response) => this.#getActionBasedOnLoginResponse(response)),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: delete the comment and fix the error
          catchError((error) => of(authActions.loginFail({ payload: error }))),
        ),
      ),
      // eslint-disable-next-line unicorn/prevent-abbreviations -- TODO: delete the comment and fix the error
      switchMap((responseObj) => {
        if (responseObj.type === authActions.loginFail.type) {
          // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects -- TODO: delete the comment and fix the error
          return [
            responseObj,
            RouterActions.go({ payload: { path: ['login'] } }),
          ];
        }
        // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects -- TODO: delete the comment and fix the error
        return [responseObj];
      }),
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
  readonly loginSuccess$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(authActions.loginSuccess),
      map(({ payload }) => payload),
      tap((payload) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- TODO: delete the comment and fix the error
        const user = payload.payload.data.user;
        this.#authService.preserveInformationToStorage(payload.payload);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- TODO: delete the comment and fix the error
        this.#authService.updatePermissions(user.roles);
      }),
      map((payload) => {
        const userRole = this.#authService.profile?.roles[0]?.name;
        const section = this.#route.snapshot.queryParamMap.get('section') || '';
        const hash = payload.hash || '';
        let redirectTo: string;
        switch (section) {
          case 'user': {
            redirectTo = defaultRedirectPath.userManagement;
            break;
          }
          case 'account': {
            redirectTo = defaultRedirectPath.accountManagement;
            break;
          }
          case 'dashboard': {
            redirectTo = '';
            break;
          }
          case 'company': {
            redirectTo = defaultRedirectPath.companyManagement;
            break;
          }
          case 'category': {
            redirectTo = defaultRedirectPath.accountManagement;
            break;
          }
          case 'action': {
            redirectTo = defaultRedirectPath.actions;
            break;
          }
          case 'supplier': {
            redirectTo = defaultRedirectPath.insightsSuppliers;
            break;
          }
          case 'analytics': {
            redirectTo = defaultRedirectPath.analytics;
            break;
          }
          case 'q': {
            redirectTo = `${defaultRedirectPath.q}/${hash}`;
            break;
          }
          case 'reportShared': {
            redirectTo = `${defaultRedirectPath.reportShared}/${hash}`;
            break;
          }
          default: {
            redirectTo =
              userRole === Roles.SUPPLIER ? defaultRedirectPath.logout : '';
          }
        }

        const nextQueryParameter = String(
          this.#route.snapshot.queryParams['next'],
        );

        const urlStartsWithSSOQueryParameters =
          nextQueryParameter.startsWith('/#access_token') ||
          nextQueryParameter.startsWith('/#error');

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, unicorn/prevent-abbreviations, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- TODO: delete the comment and fix the error
        const [path, queryParams] =
          /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- TODO: delete the comment and fix the error */
          (
            (!urlStartsWithSSOQueryParameters &&
              this.#route.snapshot.queryParams['next']) ||
            redirectTo
          ).split('?');
        /* eslint-enable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */

        return fromRoot.go({
          payload: {
            path: [path],
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: delete the comment and fix the error
            query: getQueryStringParams(queryParams),
          },
        });
      }),
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
  readonly verifyHash$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(authActions.verify),
      map((action) => action.payload),
      switchMap((payload) =>
        this.#authService.loginWithHash(payload.hash, payload.module).pipe(
          map((response) => {
            if (response.status) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- TODO: delete the comment and fix the error
              const user = response.data?.['user'];
              const userManagementSettings = createUserManagementSettings(user);
              return authActions.loginSuccess({
                payload: {
                  payload: response,
                  hash: payload.hash,
                  module: payload.module,
                  userManagementSettings,
                },
              });
            }
            return authActions.loginFail({ payload: response });
          }),
          switchMap((response) => {
            if (response.type === authActions.loginFail.type) {
              // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects -- TODO: delete the comment and fix the error
              return [response, fromRoot.go({ payload: { path: ['/login'] } })];
            }
            // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects -- TODO: delete the comment and fix the error
            return [response];
          }),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: delete the comment and fix the error
          catchError((error) => of(authActions.loginFail({ payload: error }))),
        ),
      ),
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
  readonly logout$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(authActions.logout),
      map(() => authActions.logoutSuccess()),
      tap(() => {
        const { usePendo } = environment;
        if (usePendo) {
          pendo.updateOptions({});
        }

        this.#intercomService.shutdown();
      }),
    );
  });

  readonly loginFail = createEffect(
    // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
    () => {
      return this.#actions$.pipe(
        ofType(authActions.loginFail),
        tap(() => {
          this.#authService.removeInformationFromStorage();
        }),
      );
    },
    { dispatch: false },
  );

  // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
  readonly logoutResponse$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(authActions.logoutSuccess, authActions.logoutFail),
      tap(() => {
        this.#authService.removeInformationFromStorage();
      }),
      map(() => fromRoot.go({ payload: { path: ['login'] } })),
    );
  });

  readonly fetchProfile$ = createEffect(
    // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
    () => {
      return this.#actions$.pipe(
        ofType(authActions.fetchProfile),
        map((action) => action.payload),
        tap((payload) => {
          this.#authService.updatePermissions(payload?.roles ?? []);
          const { usePendo } = environment;
          if (usePendo) {
            const {
              email,
              id,
              name,
              oldId,
              phone,
              roles,
              userLanguage,
              username,
            } = payload ?? {};

            const {
              name: companyName,
              id: companyId,
              first_account_id: accountId,
              first_account_name: accountName,
            } = payload?.company || {};
            const { country } = payload?.address || {};
            const { name: userType } = payload?.roles?.[0] || {};

            const account = {
              id: accountId,
              name: accountName,
              companyName,
            };

            const visitor = {
              id: isNumber(id) ? String(id) : 'unknown',
              full_name: username,
              role: roles?.[0]?.name,
              email,
              name,
              phone,
              oldId,
              companyName,
              userLanguage,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- TODO: delete the comment and fix the error
              companyId: companyId!,
              type: userType,
              country,
            };

            // eslint-disable-next-line unicorn/no-array-callback-reference -- TODO: delete the comment and fix the error
            const isAccountSet = Object.values(account).every(isTruthy);

            if (isAccountSet) {
              pendo.updateOptions({
                visitor,
                account,
              });
            } else {
              // TODO: cover !isAccountSet
            }
          }

          const userRole = payload?.roles?.[0]?.name;

          this.#intercomService.boot(
            !userRole || userRole === Roles.SUPPLIER ?
              {
                isVisitor: true,
              }
            : {
                isVisitor: false,
                user: {
                  email: payload?.email,
                  phone: payload?.phone,
                  createdAt: payload?.createdAt,
                  name: payload?.name,
                  id: payload?.intercom?.verificationId,
                  language: payload?.userLanguage,
                  hash: payload?.intercom?.hash,
                  role: MappedRoles[userRole]?.value,
                },
                company: {
                  id: payload?.company?.id,
                  name: payload?.company?.name,
                  createdAt: payload?.company?.created_at,
                },
              },
          );
        }),
      );
    },
    { dispatch: false },
  );

  // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
  readonly changePassword$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(authActions.changePassword),
      map((action) => action.payload),
      switchMap((payload) =>
        this.#authService.changePassword(payload.credentials).pipe(
          map((response) => {
            if (response.status === true) {
              return authActions.changePasswordSuccess({
                payload: {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: delete the comment and fix the error
                  response: response.data,
                  message: this.#translate.translate(
                    'shared.successPasswordChange',
                  ),
                },
              });
            }
            return authActions.changePasswordFail({ payload: response });
          }),
          catchError((error) =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: delete the comment and fix the error
            of(authActions.changePasswordFail({ payload: error })),
          ),
        ),
      ),
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
  readonly resetPassword$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(authActions.resetPassword),
      map((action) => action.payload),
      switchMap((payload) =>
        this.#authService
          .resetPassword(payload.credentials, payload.headers)
          .pipe(
            map((response) => {
              if (response.status === true) {
                return authActions.resetPasswordSuccess({
                  payload: {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: delete the comment and fix the error
                    response: response.data,
                    message: this.#translate.translate(
                      'shared.successPasswordChange',
                    ),
                  },
                });
              }
              return authActions.resetPasswordFail({ payload: response });
            }),
            catchError((error) =>
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: delete the comment and fix the error
              of(authActions.resetPasswordFail({ payload: error })),
            ),
          ),
      ),
      // eslint-disable-next-line unicorn/prevent-abbreviations -- TODO: delete the comment and fix the error
      switchMap((responseObj) => {
        if (responseObj.type === authActions.resetPasswordSuccess.type) {
          // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects -- TODO: delete the comment and fix the error
          return [
            responseObj,
            RouterActions.go({ payload: { path: ['login'] } }),
          ];
        }
        // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects -- TODO: delete the comment and fix the error
        return [responseObj];
      }),
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
  readonly changePasswordSuccess$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(authActions.changePasswordSuccess),
      // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects -- TODO: delete the comment and fix the error
      switchMap((action) => [
        authActions.logout(),
        fromMessageActions.showSuccessMessage({ payload: action }),
      ]),
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
  readonly unauthorizedRequest$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(authActions.unauthorizedRequest),
      concatLatestFrom(() => this.#store.select(selectLoadLogoutStatus)),
      switchMap(([, isLogoutInProgress]) => {
        if (isLogoutInProgress) {
          return EMPTY;
        }

        // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects -- TODO: delete the comment and fix the error
        return [
          authActions.logout(),
          fromMessageActions.showUnauthorizedMessage(),
        ];
      }),
      tap(() => {
        this.#dialog.closeAll();
      }),
    );
  });

  readonly verifyForActions$ = createEffect(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    () => {
      return this.#actions$.pipe(
        ofType(authActions.verifyForActions),
        switchMap(({ hash, codeId }) =>
          this.#actionsAuthService.verify(hash, codeId).pipe(
            tap((response) => {
              if (response.status) {
                const user = response.data.user;
                this.#authService.preserveInformationToStorage(response);
                this.#authService.updatePermissions(user.roles);
                void this.#router.navigate(
                  [
                    'impact',
                    'actions',
                    'view',
                    response.data.claims.workflow_id,
                  ],
                  {
                    queryParams: {
                      [ACCOUNT_ID_PARAMETER_NAME]: String(user.accounts[0].id),
                    },
                  },
                );
              }
            }),
            map((response) => {
              if (response.status) {
                const { system_id, code_id, recipient_type, workflow_id } =
                  response.data.claims;
                const customClaims: ActionsCustomClaims = {
                  systemId: Number(system_id),
                  codeId: code_id,
                  recipientType: recipient_type,
                  actionId: workflow_id,
                };
                return authActions.verifyForActionsSuccess({ customClaims });
              }

              return authActions.verifyForActionsFail({
                error: response.data,
              });
            }),
            catchError((error: unknown) =>
              of(authActions.verifyForActionsFail({ error })),
            ),
          ),
        ),
      );
    },
  );

  readonly verifyForActionsFail$ = createEffect(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    () => {
      return this.#actions$.pipe(
        ofType(authActions.verifyForActionsFail),
        tap(() => void this.#router.navigate(['/invalid-link'])),
      );
    },
    { dispatch: false },
  );

  readonly verifyForActionsSuccess$ = createEffect(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    () => {
      return this.#actions$.pipe(
        ofType(authActions.verifyForActionsSuccess),
        tap(({ customClaims }) =>
          this.#actionsAuthService.preserveCustomClaimsToStorage(customClaims),
        ),
      );
    },
    { dispatch: false },
  );

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly fetchCustomClaims$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(authActions.fetchCustomClaims),
      map(() => {
        const customClaims = this.#actionsAuthService.getCustomClaims();
        return customClaims === undefined ?
            authActions.fetchCustomClaimsFail()
          : authActions.fetchCustomClaimsSuccess({ customClaims });
      }),
    );
  });

  readonly unsubscribeNotifications$ = createEffect(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    () => {
      return this.#actions$.pipe(
        ofType(authActions.unsubscribeNotifications),
        switchMap(({ token }) =>
          this.#notificationsService.unsubscribeNotifications(token).pipe(
            map(() => authActions.unsubscribeNotificationsSuccess()),
            catchError((error: unknown) =>
              of(authActions.unsubscribeNotificationsFail({ error })),
            ),
          ),
        ),
      );
    },
  );

  readonly unsubscribeNotificationsSuccess$ = createEffect(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    () => {
      return this.#actions$.pipe(
        ofType(authActions.unsubscribeNotificationsSuccess),
        map(() =>
          fromMessageActions.enqueueSuccessSnackbar({
            configuration: {
              translationKey: 'shared.genericSuccess',
            },
          }),
        ),
      );
    },
  );

  readonly unsubscribeNotificationsFail$ = createEffect(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    () => {
      return this.#actions$.pipe(
        ofType(authActions.unsubscribeNotificationsFail),
        map(() =>
          fromMessageActions.enqueueErrorSnackbar({
            configuration: {
              translationKey: 'shared.genericError',
            },
          }),
        ),
      );
    },
  );

  readonly enqueueErrorSnackbar$ = createEffect(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    () => {
      return this.#actions$.pipe(
        ofType(
          authActions.getSSOConnectionFail,
          authActions.getAccountSSOConnectionFail,
        ),
        map((action) =>
          fromMessageActions.enqueueErrorSnackbar({
            configuration: {
              translationKey: this.#getSnackbarErrorMessageKey(action),
            },
          }),
        ),
      );
    },
  );

  #getSnackbarErrorMessageKey(action: Action): string {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (action.type) {
      case authActions.getSSOConnectionFail.type:
      case authActions.getAccountSSOConnectionFail.type: {
        return 'authModule.domainNotFound';
      }
      default: {
        return 'shared.defaultErrorMessage';
      }
    }
  }

  // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
  readonly showSuccessMessage$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(authActions.sendLinkSuccess, authActions.resetPasswordSuccess),
      map((action) =>
        fromMessageActions.showSuccessMessage({ payload: action }),
      ),
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
  readonly showErrorMessage$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(
        authActions.loginFail,
        authActions.resetPasswordFail,
        authActions.sendLinkFail,
      ),
      map((action) => fromMessageActions.showFailMessage({ payload: action })),
    );
  });

  readonly changePasswordFail$ = createEffect(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    () => {
      return this.#actions$.pipe(
        ofType(authActions.changePasswordFail),
        map(({ payload }) => ({
          message:
            'data' in payload && isString(payload.data) ?
              String(payload.data)
            : payload.message,
        })),
        filter(isTruthy),
        map(({ message }) =>
          fromMessageActions.enqueueErrorSnackbar({
            configuration: {
              translationKey: message,
            },
          }),
        ),
      );
    },
  );

  #getActionBasedOnLoginResponse(response: ApiResponse): Action {
    if (response.status === true) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- TODO: delete the comment and fix the error
      const user = response?.data?.user;
      const userManagementSettings = createUserManagementSettings(user);

      return authActions.loginSuccess({
        payload: { payload: response, userManagementSettings },
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: delete the comment and fix the error
    return authActions.loginFail({ payload: response.data });
  }
}
