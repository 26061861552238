import type { SignupFormDTO } from './signup.dto.models';

export type SignupForm = SignupFormDTO;

export const signupFormDTOsToSignupForms = (
  signupFormDTOs: SignupFormDTO[],
): SignupForm[] =>
  signupFormDTOs
    .map((signupFormDTO) => ({
      ...signupFormDTO,
      name: extractFormNameSubstring(signupFormDTO.name),
    }))
    .filter((form): form is SignupForm => form.name !== undefined);

export const extractFormNameSubstring = (
  name: SignupFormDTO['name'],
): SignupForm['name'] | undefined => {
  // eslint-disable-next-line sonarjs/slow-regex -- TODO: delete the comment and fix the error
  const regex = /\[TECH] (.*?)(?= - [^-]*\([^)]*\)$)/;

  const match = regex.exec(name);

  if (match && match[1]) {
    return match[1];
  }

  return undefined;
};
