import type { NavigationExtras } from '@angular/router';
import { createAction, props } from '@ngrx/store';

export const go = createAction(
  '[Router] Go',
  props<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: delete the comment and fix the error
    payload: { path: any[]; query?: object; extras?: NavigationExtras };
  }>(),
);

export const back = createAction('[Router] Back');

export const forward = createAction('[Router] Forward');
